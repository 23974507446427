import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';


const CounterUpOne = () => {
    return (
        <div className="section section-padding ">
            <div className="container">
            <SectionTitle 
                    subtitle=""
                    title="Why Choose Us?"
                    description="IT iON  is relentlessly on the rise, expanding our client base with reliable services and deliverables. We strive to make a positive and long-term business impact by going beyond the client-vendor relationship and becoming a comprehensive part of our customers’ enterprises.
                    <br> We offer a full suite of IT Solutions under the IT iON  – Enterprise Management System, including standard products, software solutions, and custom application development. Our integrated products allow you to start with any one product and easily add others to fit your needs."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp colSize="col-lg-3 col-6" layoutStyle="counterup-style-2" evenTopMargin="mt--90 mt_md--0" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;