import React from "react";
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';

const jobListings = [
  {
    id: "IS012501",
    title: "1. Site Reliability Engineer",
    description: `Site Reliability Engineer to design and develop reliable and scalable systems based on software engineering principles. 
    Set up performance monitoring dashboards, alerts, and analytics within Dynatrace to identify and mitigate performance bottlenecks, 
    potential issues, and areas for improvement. Investigate, diagnose, and resolve complex performance issues across various application layers, 
    from infrastructure to code-level analysis. Generate regular performance reports, create actionable insights, and suggest optimizations for continuous improvement. 
    Develop automated scripts and solutions to enhance monitoring capabilities and improve overall application resilience.`,
    requirements: [
      "Expertise in configuring and managing Dynatrace SaaS/Managed solutions.",
      "Familiarity with cloud platforms (AWS, Azure, Google Cloud) and containerized environments (Docker, Kubernetes).",
      "Strong understanding of application architectures, microservices, and DevOps principles.",
      "Experience with scripting languages (e.g., Python, Shell) for automation.",
      "Solid knowledge of databases, application servers, and common infrastructure components."
    ]
  },
  {
    id: "IS012502",
    title: "2. Sr. Site Reliability Engineer",
    description: `Site Reliability Engineer to design and develop reliable and scalable systems based on software engineering principles. 
    Set up performance monitoring dashboards, alerts, and analytics within Dynatrace to identify and mitigate performance bottlenecks, 
    potential issues, and areas for improvement. Investigate, diagnose, and resolve complex performance issues across various application layers, 
    from infrastructure to code-level analysis. Generate regular performance reports, create actionable insights, and suggest optimizations for continuous improvement. 
    Develop automated scripts and solutions to enhance monitoring capabilities and improve overall application resilience.`,
    requirements: [
      "Expertise in configuring and managing Dynatrace SaaS/Managed solutions.",
      "Familiarity with cloud platforms (AWS, Azure, Google Cloud) and containerized environments (Docker, Kubernetes).",
      "Strong understanding of application architectures, microservices, and DevOps principles.",
      "Experience with scripting languages (e.g., Python, Shell) for automation.",
      "Solid knowledge of databases, application servers, and common infrastructure components."
    ]
  },
  {
    id: "IS022501",
    title: "3. Frontend Developer/UI Developer",
    description: `The candidate should have a strong background in TypeScript/JavaScript and be proficient in one or more frameworks such as AngularJS, React, VueJS, or AlpineJS.
    Additionally, a solid understanding of Java, HTML, and CSS/SCSS is essential.
    Experience with AEM Cloud as a Service is highly desirable. The candidate should also possess excellent leadership skills and be capable of leading a team and offshore.`,
    requirements: [
      "Develop and maintain user interfaces using TypeScript/JavaScript frameworks (AngularJS, React, VueJS, AlpineJS).",
      "Collaborate with backend developers to integrate APIs and services.",
      "Ensure the technical feasibility of UI/UX designs.",
      "Optimize applications for maximum speed and scalability.",
      "Write clean, maintainable, and efficient code.",
      "Lead and mentor a team of frontend developers working in multiple time zones.",
      "Participate in code reviews and contribute to the continuous improvement of the development process."
    ]
  },
  {
    id: "IS022502",
    title: "4. Performance Tester",
    description: `Design, develop, and execute performance test scripts using JMeter and BlazeMeter. 
    Conduct load, stress, endurance, and scalability testing on web applications and APIs. 
    Analyze performance test results, identify bottlenecks, and provide recommendations for optimization. 
    Configure test environments and integrate performance tests with CI/CD pipelines. 
    Monitor application performance using relevant APM tools (e.g., New Relic, Dynatrace, AppDynamics). 
    Work closely with developers, DevOps, and QA teams to improve system performance. 
    Prepare detailed performance test reports and present findings to stakeholders. 
    Continuously enhance performance testing strategies and best practices.`,
    requirements: [
      "Design, develop, and execute performance test scripts using JMeter and BlazeMeter.",
      "Conduct load, stress, endurance, and scalability testing on web applications and APIs.",
      "Analyze performance test results, identify bottlenecks, and provide recommendations for optimization.",
      "Configure test environments and integrate performance tests with CI/CD pipelines.",
      "Monitor application performance using relevant APM tools (e.g., New Relic, Dynatrace, AppDynamics).",
      "Work closely with developers, DevOps, and QA teams to improve system performance.",
      "Prepare detailed performance test reports and present findings to stakeholders.",
      "Continuously enhance performance testing strategies and best practices."
    ]
  }
];

const Careers = () => {
  return (
    <>
      <SEO title="Careers" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Careers" page="Careers" />

        <div className="section ">
          <div className="container">
            <p style={{ textAlign: "center",fontWeight:"bold" }}>Join Our Team</p>

            {jobListings.map((job) => (
              <div key={job.id} style={{ borderBottom: "1px solid #ddd"}}>
                <p style={{  fontWeight:"bold" }}>{job.title} ({job.id})</p>
                <p>{job.description}</p>
                <p style={{  fontWeight:"bold" }}>Requirements:</p>
                <ul>
                  {job.requirements.map((requirement, index) => (
                    <li key={index}>{requirement}</li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Required Skills & Qualifications Section */}
            <div style={{ marginTop: "30px" }}>
              <p style={{  fontWeight:"bold" }} >Required Skills & Qualifications:</p>
              <ul>
                <li>Strong proficiency in JMeter for script creation and execution.</li>
                <li>Hands-on experience with BlazeMeter for cloud-based performance testing.</li>
                <li>Knowledge of HTTP, REST APIs, WebSockets, and database performance tuning.</li>
                <li>Experience in analyzing logs, server metrics, and system monitoring tools.</li>
                <li>Familiarity with CI/CD tools (Jenkins, Git, Docker, Kubernetes) for performance test integration.</li>
                <li>Basic scripting knowledge in Python, Java, or JavaScript for test automation.</li>
                <li>Excellent problem-solving and communication skills.</li>
              </ul>
            </div>

            {/* Email Section at the End */}
            <p style={{ textAlign: "start", fontSize: "16px", marginTop: "30px" }}>
              <b>Please mail resumes to: </b>
              <a href="mailto:info@itonsolutions.com"> info@itonsolutions.com</a>
            </p>
          </div>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Careers;
